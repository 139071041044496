import renderComponents from './components';

const NAME = 'sw-search-users';
const LABELSEARCHINPUT = 'Введите имя или email пользователя';

class SearchUsersModule {
  constructor(name, context, opts) {
    this.name = name;
    this.box = opts.box;
    this.context = context;

    const searchUsersService = context.getService('SearchUsers');
    searchUsersService.setSearchUsers(LABELSEARCHINPUT);

    this.page = renderComponents(this.box, {
      searchUsersService,
      toUserCartsAndNote: this.toUserCartsAndNote,
    });
  }

  toUserCartsAndNote = (user) => {
    const path = `/account/${user.MultiCompanyId}/company/${user.Companies[0].CompanyId}/user/${user.Id}`;

    this.context.navigate(path);
  };

  destroy() {
    this.page.unmount();
  }
}

export default function (core) {
  core.addModule(NAME, SearchUsersModule);
}
