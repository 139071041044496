import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';

import { Dialog, Checkbox, Paginate, MultiSelect, Tooltip, Button } from 'sw-ui';

import { diffDays, formatDate, getMoment, isMoment } from '../../bi/utils/formatDate';

import AjaxButton from '../../components/ajaxButton';
import { FlatButton } from '../../components/button';
import MaterialIcon from '../../components/materialIcon';
import RangeDatePicker from '../../components/RangeDatePicker';
import NoResult from '../../components/NoResult';
import scrollToTop from '../../utils/scrollToTop';

import COMPONENTS from '../../bi/constants/components';
import { PERIOD_SHIPMENT } from '../../bi/constants/account';

import styles from './styles.module.css';

const LABELS = {
  CANCEL: 'Отменить',
  CONTINUE: 'Продолжить',
  CLOSE: 'Закрыть',
  SENT: 'Отправить',
  FILTER: 'Фильтр',
  DATE: 'Дата',
  SHOW: 'Показать',
  DOWNLOADXLS: 'Скачать xls',
};

const TEXT = {
  DOC_SENT_SUCCESS: 'Документы успешно отправлены',
  DOC_NO_SENT: 'Не удалось отправить документы',
  SENT: 'Отправлен',
  NO_SENT: 'Не отправлен',
  ERROR_MESSAGE: 'При отправке произошла ошибка, обратитесь в поддержку',
  FILTER: 'Период отправки документов',
};

const DAYS = {
  END_MONTH: 31,
};

const ERROR = {
  DIFF: `Период между датами должен быть не более ${DAYS.END_MONTH} дня`,
};

class SendingDiadoc extends Component {
  static propTypes = {
    sendingDiadocService: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const {
      startDate,
      endDate,
      maxDay,
      status,
      period,
      currentPage,
      itemsOnPage,
      totalItems,
      allCheckboxValue,
      listCompany,
      source,
      packagesFilter,
    } = props.sendingDiadocService.get();

    this.state = {
      startDate,
      endDate,
      maxDay,
      period,
      listCompany,
      source,
      status,
      currentPage,
      itemsOnPage,
      totalItems,
      allCheckboxValue,
      packagesFilter,
      maxDateLimit: getMoment(),

      loadingButton: false,
      loadingSendButton: false,

      dialogError: false,
      dialogAttentionIsOpen: false,
      successDialog: false,

      selectedValue: [],
      valueFilter: 0,
    };
  }

  componentDidMount() {
    this.unsubscribe = this.props.sendingDiadocService.subscribe(this.updateState);
    this.props.sendingDiadocService.getAllSendingStatus();
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  updateState = state => this.setState({
    ...state,
  });

  handleChangeDate = (field, value) => {
    if (!isMoment(value)) {
      return;
    }

    return this.props.sendingDiadocService.changeDate(field, value);
  };

  handleChangeCheckboxItem = (companyId, value) => this.props.sendingDiadocService.changeCheckboxItem(value, companyId);

  handleSelectAll = value => this.props.sendingDiadocService.selectAllItemsOnCurrentPage(value);

  handleStatusChanged = (values) => {
    const { listCompany, status: { selected } } = this.state;

    if (!values.length || (!listCompany.length && selected === 2)) {
      this.setState({
        valueFilter: 0,
      });
    }
    this.setState({
      selected: values,
    });
    this.props.sendingDiadocService.changeSelectStatus(values);
  }

  handleFilterChanged = (values) => {
    this.setState({
      selectedValue: values,
      period: values,
    });
    this.props.sendingDiadocService.changeSelectPeriod(values);
  }

  handlePageChange = (event, page) => this.props.sendingDiadocService.changeCurrentPage(page);

  downloadXlsDocument = () => this.props.sendingDiadocService.downloadXlsDocument();

  getPackageDocuments = () => {
    const { period } = this.state;

    this.setState({
      loadingButton: true,
      valueFilter: 0,
      period,
    });
    this.props.sendingDiadocService.getPackageDocuments()
      .then(() => {
        this.setState({
          loadingButton: false,
        });
      });
  };

  handleSend = () => {
    const { sendingDiadocService } = this.props;
    const findSentDoc = sendingDiadocService.findSentDocuments();

    if (findSentDoc) {
      this.setState({
        dialogAttentionIsOpen: true,
      });

      return;
    }
    this.setState({
      loadingSendButton: true,
    });
    sendingDiadocService.sendDiadoc()
      .then(() => {
        this.setState({
          loadingSendButton: false,
          successDialog: true,
          valueFilter: 0,
        });
      })
      .catch(() => this.setState({
        dialogError: true,
      }));
  };

  handleOpenNewWidow = (AccountId, CompanyId) => {
    const generatePath = () => `${window.location.origin}/account/${AccountId}/company/${CompanyId}`;
    window.open(generatePath(AccountId, CompanyId), '_blank');
  };

  validate = () => {
    const { startDate, endDate } = this.state;
    let validateErr = '';

    const diff = diffDays(startDate, endDate);

    if (diff > DAYS.END_MONTH) {
      validateErr = ERROR.DIFF;

      return validateErr;
    }

    return validateErr;
  };

  toggleError = () => this.setState({ dialogError: !this.state.dialogError });

  statusSelection = sendingStatus => (sendingStatus === 2 ? TEXT.SENT : TEXT.NO_SENT);

  dateSelection = sendingDate => (sendingDate ? formatDate(sendingDate) : null);

  renderAttentionDialog = () => (
    this.state.dialogAttentionIsOpen &&
    <Dialog onClick={ () => this.setState({ dialogAttentionIsOpen: false }) }>
      <div className={ styles.dialog }>
        <div className={ styles['dialog-text'] }>
          Среди этих пакетов есть отправленные
        </div>
        <div className={ styles['dialog-button'] }>
          <FlatButton
            label={ LABELS.CANCEL }
            onClick={ () => this.setState({ dialogAttentionIsOpen: false }) }
          />
        </div>
        <div className={ styles['button-wrap'] }>
          <Button
            label={ LABELS.CONTINUE }
            onClick={ this.handleSend }
          />
        </div>
      </div>
    </Dialog>
  );

  renderSuccessDialog = () => {
    const { successDialog } = this.state;

    return (
      successDialog &&
      <Dialog onClick={ () => this.setState({ successDialog: false }) }>
        <div className={ styles.dialog }>
          <div className={ styles['dialog-text'] }>
            { TEXT.DOC_SENT_SUCCESS }
          </div>
          <div className={ styles['dialog-button'] }>
            <FlatButton
              label={ LABELS.CLOSE }
              onClick={ () => this.setState({ successDialog: false }) }
            />
          </div>
        </div>
      </Dialog>
    );
  };

  renderGenerateDocumentDialog = () => (
    this.state.dialogError &&
    <NoResult
      message={ TEXT.ERROR_MESSAGE }
      onClose={ this.toggleError }
    />
  );

  renderCompanies = () => {
    const { listCompany } = this.state;

    return listCompany.map(({
      Number,
      Id,
      Selected,
      AccountId,
      CompanyId,
      CompanyName,
      CreatedDate,
      PeriodStart,
      PeriodEnd,
      SendingStatus,
      SendingDate,
      SendingPeriod,
    }) => (
      <div className={ `${styles.row} ${styles['company-item']}` } key={ Number }>
        <div className='sw-tooltip-wrapper'>
          <Checkbox
            onChange={ value => this.handleChangeCheckboxItem(Id, value) }
            value={ Selected }
            className={ styles['select-company'] }
          />
        </div>
        <div
          className={ `${styles.title} ${styles.col} ${styles['col-1-5-9']} ${styles['company-name']}` }
          onClick={ () => this.handleOpenNewWidow(AccountId, CompanyId) }
        >
          { CompanyName }
        </div>
        <div className={ `${styles.col} ${styles['col-1-9']}` }>
          { CompanyId }
        </div>
        <div className={ `${styles.col} ${styles['col-1-9']}` }>
          { Number }
        </div>
        <div className={ `${styles.col} ${styles['col-1-9']}` }>
          { PERIOD_SHIPMENT[SendingPeriod].label }
        </div>
        <div className={ `${styles.col} ${styles['col-1-9']}` }>
          {this.dateSelection(SendingDate) }
        </div>
        <div className={ `${styles.col} ${styles['col-1-9']}` }>
          {formatDate(CreatedDate)}
        </div>
        <div className={ `${styles.col} ${styles['col-1-9']}` }>
          {formatDate(PeriodStart)} - {formatDate(PeriodEnd)}
        </div>
        <div className={ `${styles.col} ${styles['col-1-9']}` }>
          { this.statusSelection(SendingStatus) }
        </div>
      </div>
      ));
  };

  renderCompanyList = () => {
    const {
      source,
      itemsOnPage,
      currentPage,
      loadingSendButton,
      allCheckboxValue,
      totalItems,
    } = this.state;

    if (!source.length) return null;

    return (
      <div ref={ (elem) => { this.tableHeader = elem; } } className={ styles.wrap }>
        <div className={ styles['top-control-panel'] }>
          <div className={ styles['button-wrap'] }>
            <AjaxButton
              label={ LABELS.DOWNLOADXLS }
              theme={ COMPONENTS.BUTTON.THEME.FLAT }
              onClick={ this.downloadXlsDocument }
            />
          </div>
          <div className={ styles['button-wrap'] }>
            <AjaxButton
              label={ LABELS.SENT }
              loading={ loadingSendButton }
              onClick={ this.handleSend }
            />
          </div>
        </div>
        <div className={ `${styles.row} ${styles['row-title']}` }>
          <div className='sw-tooltip-wrapper'>
            <Checkbox
              onChange={ this.handleSelectAll }
              value={ allCheckboxValue }
              className={ styles['select-company'] }
            />
          </div>
          <div className={ `${styles.col} ${styles['col-1-5-9']}` }>
            Название компании
          </div>
          <div className={ `${styles.col} ${styles['col-1-9']}` }>
            id компании
          </div>
          <div className={ `${styles.col} ${styles['col-1-9']}` }>
            Номер пакета
          </div>
          <div className={ `${styles.col} ${styles['col-1-9']}` }>
            Период отправки документов
          </div>
          <div className={ `${styles.col} ${styles['col-1-9']}` }>
            Дата отправки
          </div>
          <div className={ `${styles.col} ${styles['col-1-9']}` }>
            Дата формирования
          </div>
          <div className={ `${styles.col} ${styles['col-1-9']}` }>
            Период за который сформирован пакет документов
          </div>
          <div className={ `${styles.col} ${styles['col-1-9']}` }>
            Статус
          </div>
        </div>
        { this.renderCompanies() }
        <div className={ styles['bottom-control-panel'] }>
          <div className={ styles['button-to-top'] } onClick={ () => scrollToTop(this.tableHeader) }>
            <MaterialIcon icon='arrow_upward' />
            <span>К началу страницы</span>
          </div>
          <Paginate
            handlePrevious={ this.handlePageChange }
            handlePage={ this.handlePageChange }
            handleNext={ this.handlePageChange }
            currentPage={ currentPage }
            itemsOnPage={ itemsOnPage }
            totalItems={ totalItems }
          />
        </div>
      </div>
    );
  };

  render() {
    const errorMsg = this.validate();
    const tooltipButton = errorMsg ? (
      <Tooltip position={ COMPONENTS.TOOLTIP.POSITION.BOTTOM }>
        <div className={ styles.tooltip }>{ errorMsg }</div>
      </Tooltip>) : null;

    const {
      startDate,
      endDate,
      maxDay,
      loadingButton,
      maxDateLimit,
      status: { selected, list },
      selectedValue,
    } = this.state;

    return (
      <div className={ styles.main }>
        <div className={ styles['render-panel'] }>
          <RangeDatePicker
            startDate={ startDate }
            endDate={ endDate }
            maxDate={ maxDay }
            onChange={ this.handleChangeDate }
            label={ LABELS.DATE }
            maxDateLimit={ maxDateLimit }
          />
          <div className={ styles.main_filter }>
            <MultiSelect
              label={ LABELS.FILTER }
              items={ list }
              values={ selected }
              onChange={ this.handleStatusChanged }
            />
          </div>
          <div className={ styles.main_filter }>
            <MultiSelect
              label={ TEXT.FILTER }
              items={ PERIOD_SHIPMENT }
              values={ selectedValue }
              onChange={ this.handleFilterChanged }
              selectAll
            />
          </div>
          <div className={ `${styles.actions} sw-tooltip-wrapper` }>
            { tooltipButton }
            <div className={ styles['main-button'] }>
              <AjaxButton
                label={ LABELS.SHOW }
                loading={ loadingButton }
                onClick={ this.getPackageDocuments }
                disabled={ !!errorMsg }
              />
            </div>
          </div>
        </div>
        { this.renderCompanyList() }
        { this.renderAttentionDialog() }
        { this.renderGenerateDocumentDialog() }
        { this.renderSuccessDialog() }
      </div>
    );
  }
}

const renderComponents = (box, opts) => {
  const root = createRoot(box);

  root.render(
    <SendingDiadoc
      appService={ opts.appService }
      goBack={ opts.goBack }
      companyService={ opts.companyService }
      sendingDiadocService={ opts.sendingDiadocService }
    />
  );

  return root;
};

export default renderComponents;
