import renderComponents from './components';

const NAME = 'sw-revise-act';

class ReviseAct {
  constructor(name, context, opts) {
    this.box = opts.box;
    this.name = name;
    this.context = context;

    const appService = this.context.getService('App');
    const reviseActTestService = this.context.getService('ReviseActTest');
    this.page = renderComponents(this.box, {
      appService,
      reviseActTestService,
      goBack: this.goBack,
    });
  }

  goBack = () => {
    history.back();
  };

  destroy() {
    this.page.unmount();
  }
}

export default function (core) {
  core.addModule(NAME, ReviseAct);
}
