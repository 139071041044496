import renderComponents from './components';

const NAME = 'sw-sending-diadoc';

class SendingDiadoc {
  constructor(name, context, opts) {
    this.box = opts.box;
    this.name = name;
    this.context = context;

    const appService = this.context.getService('App');
    const companyService = this.context.getService('Company');
    const sendingDiadocService = this.context.getService('SendingDiadoc');

    this.page = renderComponents(this.box, {
      appService,
      companyService,
      sendingDiadocService,
      goBack: this.goBack,
      moveToCompany: this.moveToCompany,
    });
  }

  goBack = () => {
    history.back();
  };

  destroy() {
    this.page.unmount();
  }
}

export default function (core) {
  core.addModule(NAME, SendingDiadoc);
}
